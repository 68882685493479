import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import insta from "../assets/img/flatart-insta-ss.png"
import insta1 from "../assets/img/flatart-insta-ss1.png"
import insta2 from "../assets/img/flatart-insta-ss2.png"
import office from "../assets/img/ank.jpg"
import office1 from "../assets/img/ist.jpg"
import peopleIcon from "../assets/img/people-icon.png"
import mailIcon from "../assets/img/mail-icon.png"
import callIcon from "../assets/img/call-icon.png"

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: "",
    }
  }
  handleSubmit(e) {
    e.preventDefault()
    const response = fetch(
      "https://forms.flatart.com.tr/index.php?r=app%2Ff&id=8",
      {
        method: "POST",
        body: JSON.stringify({
          text_457735: document.querySelector("#ad").value,
          text_967447: document.querySelector("#eposta").value,
          text_314200: document.querySelector("#sirket").value,
          text_959769: document.querySelector("#telefon").value,
          text_901991: document.querySelector("#mesaj").value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
    response.then(res => {
      if (res.status === 200) {
        document.querySelector(".status").innerHTML =
          "Mesajınız başarıyla gönderilmiştir."
        document.querySelector("#ad").value = ""
        document.querySelector("#eposta").value = ""
        document.querySelector("#sirket").value = ""
        document.querySelector("#telefon").value = ""
        document.querySelector("#mesaj").value = ""
      } else {
        document.querySelector(".status").innerHTML = "Bir hata oluştu."
      }
    })
  }
  render() {
    return (
      <Layout>
        <SEO
          title="İletişim"
          description="Sizi Ankara ve İstanbul ofislerimize
            kahveye bekliyoruz."
        />
        <div className="contact container" style={{marginTop:20 }}>
          <h3>BİZE ULAŞIN</h3>
          <h1 id="contactH1">
            Asya ve Avrupa <br />
            kıtalarının buluşma <br />
            noktasındayız.{" "}
          </h1>
          <p className="contactp">
            Sizi Ankara ve İstanbul ofislerimize
            kahveye bekliyoruz.{" "}
          </p>
          <div className="offices">
            <div className="offices-city col-md-6">
              <img alt="alt text" src={office}></img>
              <h2>Ankara</h2>
              <p>
              Ümit Mah. Bina No:2 Nokta Kule<br />
              5. Kat Daire No:6 Çankaya/ANKARA


              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/dir/39.9048704,32.6926336/flatart/@39.9029452,32.6905496,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14d3470555555555:0x573dd00dc266ec0f!2m2!1d32.6929533!2d39.900412"
                className="content-link"
              >
                Ankara ofisimiz için tarif alın.
              </a>
            </div>
            <div className="offices-city col-md-6">
              <img alt="alt text" src={office1}></img>
              <h2>İstanbul</h2>
              <p>
                Dikilitaş Mah. Yenigelin Sok.<br /> No:6/15 Beşiktaş/İSTANBUL
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Dikilita%C5%9F,+Yenigelin+Sk+No:6,+34349+Be%C5%9Fikta%C5%9F%2F%C4%B0stanbul/data=!4m2!3m1!1s0x14cab7ac61a593c7:0xdf5d5dc3a07ab31f?sa=X&ved=2ahUKEwiIq5el47nmAhUktHEKHaZBDoMQ8gEwAHoECAsQAQ"
                className="content-link"
              >
                İstanbul ofisimiz için tarif alın.
              </a>
            </div>
          </div>
        </div>
        <div className="contact-row container">
          <div className="col-md-4">
            <img alt="alt text" src={peopleIcon}></img>
            <p>İş birlikleri ve projeniz için yapabileceklerimizi öğrenin.</p>
            <a className="content-link" href="mailto:partners@theflatart.com">
            partners@theflatart.com
            </a>
          </div>
          <div className="col-md-4">
            <img alt="alt text" src={mailIcon}></img>
            <p>Tanışmak için e-posta gönderin, 1 günde yanıtlayalım.</p>
            <a className="content-link" href="mailto:hi@theflatart.com ">
            hi@theflatart.com
            </a>
          </div>
          <div className="col-md-4">
            <img alt="alt text" src={callIcon}></img>
            <p>
              Konu çok acilse <br />
              telefonda konuşalım.
            </p>
            <a href="tel:+908509880200" className="content-link">
              +90 (850) 988 02 00
            </a>
          </div>
        </div>

        <div className="contact-end container">
          <div className="col-md-6">
            <h2 style={{marginBottom: 90}}>
              İletişim kurmanın <br />
              en kolay yolu.
            </h2>
            
            <form className="contact-end__form" onSubmit={this.handleSubmit}>
              <input
                type="text"
                name="text_457735"
                id="ad"
                placeholder="Adınızı yazın *"
              />
              <input
                type="email"
                name="text_967447"
                id="eposta"
                placeholder="E-postanızı yazın *"
              />
              <input type="text" id="telefon" name="text_959769"  placeholder="Telefon girin *" />
              <input
                type="text"
                name="text_314200"
                id="sirket"
                placeholder="Şirket"
              />
              <textarea
                id="mesaj"
                name="text_901991"
                placeholder="Mesajınız"
              ></textarea>
              <button className="red-button" type="submit" id="button_356434" name="button_356434">GÖNDER</button>
              
              <div className="status"></div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="follow">
              <h2  style={{marginBottom: 25}}>
              Hizmetlerimiz ve merak ettikleriniz hakkında bize ulaşın!
              </h2>
            </div>
           <div className="row">
            <div className="col">
               <a href="/kariyer/" target="_blank" ><img alt="flatart-kariyer"  src={insta} /></a>
            </div>
            <div className="col">
               <a href="/basari-hikayeleri/" target="_blank" ><img alt="flatart-basari-hikayeleri"  src={insta1} /></a>
            </div>
            <div className="col">
               <a href="/hizmetler/" target="_blank" ><img alt="flatart-hikayeler"  src={insta2} /></a>
            </div>

           </div>

          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact
